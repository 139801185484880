import {
  C4GameInfo,
  C4Move,
  C4MoveInfo,
  MMRYGameInfo,
  MMRYMove,
  MMRYMoveInfo,
  TTTGameInfo,
  TTTMove,
  TTTMoveInfo,
} from "./games.types";

export enum UserPictureType {
  Image = "image",
  Emoji = "emoji",
}

export enum DecisionMaker {
  Host = "host",
  Everyone = "everyone",
}

export interface UserProfile {
  name: string;
  picture: {
    type: UserPictureType;
    image: number;
    emoji: string;
    background: string;
  };
}

export interface User {
  id: string;
  data: UserProfile;
}

export interface ConnectedUser {
  id: string;
  score: number;
  voteChooseGame: Games | null;
  voteEndGame: boolean;
  data: UserProfile;
}

export interface RoomData {
  size: number;
  decisionNext: DecisionMaker;
  decisionCancel: DecisionMaker;
}

export interface Room {
  id: string;
  name: string;
  code: string;
  settings: RoomData;
}

export interface JoinRoom {
  code: string;
}

export enum MessageTypes {
  Error = "Error",
  SuccessfullyJoined = "successfullyJoined",
  LeaveRoom = "leaveRoom",
  UsersInRoom = "usersInRoom",
  SendReaction = "sendReaction",
  SendChat = "sendChat",
  ChooseGame = "chooseGame",
  StartGame = "startGame",
  MakeTurn = "makeTurn",
  VoteEndGame = "voteEndGame",
  EndGame = "endGame",
}

export interface Message {
  type: MessageTypes;
  data: Object | null;
}

export interface BackendError {
  code: string;
  message: string;
}

export interface CSChatMessage {
  message: string;
}

export interface SSChatMessage {
  senderID: string;
  message: string;
}

export enum Games {
  TicTacToe = "tictactoe",
  ConnectFour = "connectfour",
  Memory = "memory",
}

export interface CSChooseGame {
  game: Games | null;
}

export interface SSChooseGame {
  game: Games | null;
  userID: string;
}

export interface StartGame {
  game: Games;
  gameInfo: TTTGameInfo | C4GameInfo | MMRYGameInfo;
}

export interface CSMakeTurn {
  move: TTTMove | C4Move | MMRYMove;
}

export interface SSMakeTurn {
  move: TTTMove | C4Move | MMRYMove;
  moveInfo: TTTMoveInfo | C4MoveInfo | MMRYMoveInfo;
}

export interface CSVoteEndGame {
  vote: boolean;
}

export interface SSVoteEndGame {
  userID: string;
  vote: boolean;
}

export interface SSEndGame {
  winnerID: string | null;
}

export interface TobbarGameinfo {
  userID: string;
  element: JSX.Element;
}
