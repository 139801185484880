import { faCommentDots, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import "./Chat.css";

import { CSChatMessage, ConnectedUser, SSChatMessage } from "../../utils/types";
import ProfileImage from "../ProfileImage/ProfileImage";

export function Chat(props: {
  connectedUsers: ConnectedUser[];
  lastReaction: SSChatMessage | null;
  chat: SSChatMessage[];
  sendReaction: (reaction: CSChatMessage) => void;
  sendChat: (message: CSChatMessage) => void;
}) {
  const { t } = useTranslation();

  const availableReactions = ["😢", "😮", "😍", "😂", "👏"];

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const reactionRef = useRef<HTMLDivElement>(null);
  const [hideComms, setHideComms] = useState<boolean>(true);

  const getUser = (userID: string) => {
    return props.connectedUsers.find((user) => user.id === userID) ?? null;
  };

  const submitChat = () => {
    if (inputRef.current && inputRef.current.value.length > 0) {
      props.sendChat({ message: inputRef.current.value });
      inputRef.current.value = "";
    }
  };

  useEffect(() => {
    if (props.lastReaction && reactionRef.current) {
      const ele = document.createElement("div");
      ele.classList.add("recieved-reaction");
      ele.classList.add("background-elevated");
      ele.innerText = props.lastReaction.message;

      reactionRef.current.appendChild(ele);

      // Delete the element after 5 seconds to keep the dom a bit cleaner
      setTimeout(() => {
        ele.remove();
      }, 5000);
    }
  }, [props.lastReaction]);

  return (
    <div className="comms-wrapper">
      <div ref={reactionRef} className="recieved-reaction-wrapper"></div>
      <div className="side">
        <div className="toggle-wrapper">
          <button
            className="toggle interactable"
            onClick={() => setHideComms(!hideComms)}
          >
            <FontAwesomeIcon icon={faCommentDots} />
          </button>
        </div>
        <div className="reactions-wrapper">
          <div
            className={`reactions-container interactable-container ${hideComms ? "hidden" : ""}`}
          >
            {availableReactions.map((reaction, index) => (
              <button
                key={index}
                onClick={() => props.sendReaction({ message: reaction })}
                className="interactable"
              >
                {reaction}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="chat-wrapper">
        <div className={`chat-container ${hideComms ? "hidden" : ""}`}>
          <div className="input">
            <textarea
              ref={inputRef}
              placeholder={t("c.message")}
              className="interactable-container"
              onKeyDown={(event) => {
                // Submit on Enter, but not when shift is pressed
                if (event.key === "Enter" && !event.shiftKey) {
                  event.preventDefault();
                  submitChat();
                }
              }}
            ></textarea>
            <button onClick={submitChat} className="interactable">
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
          <div className="chat background-elevated">
            {props.chat.map((message, index) => (
              <div className="message" key={index}>
                <div className="image">
                  <ProfileImage user={getUser(message.senderID)} />
                </div>
                <div className="content">
                  <div className="name">
                    {getUser(message.senderID)?.data.name}
                  </div>
                  <pre className="text">{message.message}</pre>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
