import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import "./GameMemory.css";

import { AppContext } from "../../components/AppContextProvider/AppContextProvider";
import {
  MMRYGameInfo,
  MMRYMove,
  MMRYMoveInfo,
  MMRYPlayer,
  MMRYTile,
} from "../../utils/games.types";
import {
  CSMakeTurn,
  Message,
  MessageTypes,
  SSMakeTurn,
  TobbarGameinfo,
} from "../../utils/types";

export default function GameMemory(props: {
  gameInfo: MMRYGameInfo;
  setGameinfoEles: Dispatch<SetStateAction<TobbarGameinfo[]>>;
}) {
  const { wsClient } = useContext(AppContext);

  const [currentPlayer, setCurrentPlayer] = useState<string>(
    props.gameInfo.startUserID,
  );
  const [players, setPlayers] = useState<MMRYPlayer[]>(
    props.gameInfo.turnOrder,
  );
  const [field, setField] = useState<MMRYTile[]>(
    Array(12 * 2).fill({
      symbol: "",
      revealedBy: null,
      midTurnUncovered: false,
    } as MMRYTile),
  );

  const onMessage = (message: Message | null) => {
    if (!message) return;

    if (message.type === MessageTypes.MakeTurn) {
      const turn = message.data as SSMakeTurn;
      const move = turn.move as MMRYMove;
      const moveInfo = turn.moveInfo as MMRYMoveInfo;

      let newField = field;

      if (moveInfo.increaseScoreOf !== null) {
        // Current player scored
        // Mark mid turn uncovered cards as revealed
        newField = newField.map((tile) => {
          if (tile.midTurnUncovered && tile.revealedBy === null) {
            return { ...tile, revealedBy: moveInfo.increaseScoreOf };
          } else {
            return tile;
          }
        });

        // Mark newly reveald as revealed
        // With .map instead of [index] because react doesnt detect [index] as a state change
        newField = newField.map((tile, index) => {
          if (index === move.index) {
            return {
              ...tile,
              symbol: moveInfo.revealedByMove,
              revealedBy: moveInfo.increaseScoreOf,
            };
          } else {
            return tile;
          }
        });

        // Increase score
        setPlayers(
          players.map((player) => {
            if (player.userID === moveInfo.increaseScoreOf) {
              return { ...player, score: player.score + 1 };
            } else {
              return player;
            }
          }),
        );
      } else if (moveInfo.byUserID === moveInfo.nextUserID) {
        // Current player did not score but it was their first reveal
        // Mark the card as mid turn uncovered
        // With .map instead of [index] because react doesnt detect [index] as a state change
        newField = newField.map((tile, index) => {
          if (index === move.index) {
            return {
              ...tile,
              midTurnUncovered: true,
              symbol: moveInfo.revealedByMove,
            };
          } else {
            return tile;
          }
        });
      } else {
        // Current player did not score and it was not their first reveal
        // Show the card
        // With .map instead of [index] because react doesnt detect [index] as a state change
        newField = newField.map((tile, index) => {
          if (index === move.index) {
            return {
              ...tile,
              midTurnUncovered: true,
              symbol: moveInfo.revealedByMove,
            };
          } else {
            return tile;
          }
        });

        // In 3 seconds, set new player and hide not revealed cards again
        setTimeout(() => {
          let futureField = field.map((tile) => {
            if (tile.midTurnUncovered && tile.revealedBy === null) {
              return { ...tile, midTurnUncovered: false };
            } else {
              return tile;
            }
          });
          setField(futureField);
          setCurrentPlayer(moveInfo.nextUserID);
        }, 3000);
      }

      console.log("NewField after:", newField);

      setField(newField);

      console.log("Field after:", field);
    }
  };

  const makeMove = (index: number) => {
    wsClient.send({
      type: MessageTypes.MakeTurn,
      data: {
        move: {
          index,
        },
      } as CSMakeTurn,
    });
  };

  useEffect(() => {
    onMessage(wsClient.lastMessage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient.lastMessage]);

  useEffect(() => {
    props.setGameinfoEles(
      players.map((player) => {
        return {
          userID: player.userID,
          element: (
            <Gameinfo
              player={player}
              currentTurn={player.userID === currentPlayer}
            />
          ),
        } as TobbarGameinfo;
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlayer, players]);

  return (
    <div className="memorygame-wrapper">
      <div className="game-container">
        {field.map((tile, index) => (
          <button
            className="tile interactable"
            onClick={() => makeMove(index)}
            key={index}
          >
            {(tile.revealedBy !== null || tile.midTurnUncovered) && (
              <div>{tile.symbol}</div>
            )}
          </button>
        ))}
      </div>
    </div>
  );
}

function Gameinfo(props: { player: MMRYPlayer; currentTurn: boolean }) {
  const { t } = useTranslation();

  return (
    <div className="connectfour-gameinfo">
      <p>{`${t("gameMMRY.revealedPairs")}: ${props.player.score}`}</p>
      {props.currentTurn && <p>{t("c.theirTurn")}</p>}
    </div>
  );
}
