import { createContext, useEffect } from "react";
import useWebSocket from "react-use-websocket";

import { APIClient } from "../../utils/api/api";
import { APIClientInterface } from "../../utils/api/types";
import { Settings } from "../../utils/settings/settings";
import { SettingsInterface } from "../../utils/settings/types";
import { User } from "../../utils/types";
import { WSClientInteface } from "../../utils/ws/types";
import { WSClient } from "../../utils/ws/ws";

export type AppContextType = {
  settings: SettingsInterface;
  apiClient: APIClientInterface;
  wsClient: WSClientInteface;
};

export const AppContext = createContext<AppContextType>(null!);

export default function AppContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const settings = Settings();
  const apiClient = APIClient;

  // Create Websocket
  let webSocket = useWebSocket(process.env.REACT_APP_WS_ENDPOINT!, {
    share: true,
    queryParams: {
      bearer: settings.userToken ?? "",
    },
  });
  let wsClient = WSClient(webSocket);

  const storeAndConnect = (userToken: string, user: User) => {
    settings.setUserToken(userToken);
    settings.setUser(user);
  };

  useEffect(() => {
    async function fetchData() {
      // Check if a user token is stored
      if (settings.userToken) {
        // Check if the user token is valid
        try {
          const res = await APIClient.getUser(settings.userToken);
          storeAndConnect(settings.userToken, res);
        } catch (err) {
          console.error("APIClient.getUser error:", err);
        }
      }

      // If recieving didnt work
      if (!settings.userToken || !settings.user) {
        // Create a new user
        try {
          const res = await APIClient.createUser();
          storeAndConnect(res.userToken, res.user);
        } catch (err) {
          console.error("APIClient.createUser error:", err);
        }
      }
    }

    fetchData();

    // Reapply saved values
    settings.setColorScheme(settings.colorScheme);
    settings.setLanguage(settings.language);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContext.Provider
      value={{
        settings,
        apiClient,
        wsClient,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
