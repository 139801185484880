import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import "./GameConnectFour.css";

import { AppContext } from "../../components/AppContextProvider/AppContextProvider";
import {
  C4GameInfo,
  C4Move,
  C4MoveInfo,
  C4Player,
  C4Role,
} from "../../utils/games.types";
import {
  CSMakeTurn,
  Message,
  MessageTypes,
  SSMakeTurn,
  TobbarGameinfo,
} from "../../utils/types";

export default function GameConnectFour(props: {
  gameInfo: C4GameInfo;
  setGameinfoEles: Dispatch<SetStateAction<TobbarGameinfo[]>>;
}) {
  const { wsClient } = useContext(AppContext);

  const [currentPlayer, setCurrentPlayer] = useState<string>(
    props.gameInfo.startUserID,
  );
  const [field, setField] = useState<(C4Role | null)[][]>(
    Array(7)
      .fill(null)
      .map(() => Array(6).fill(null)),
  );

  const onMessage = (message: Message | null) => {
    if (!message) return;

    if (message.type === MessageTypes.MakeTurn) {
      const turn = message.data as SSMakeTurn;
      const move = turn.move as C4Move;
      const moveInfo = turn.moveInfo as C4MoveInfo;

      let newField = field;

      const row = newField[move.column].findIndex((tile) => tile === null);

      newField[move.column][row] =
        props.gameInfo.turnOrder.find(
          (player) => player.userID === moveInfo.byUserID,
        )?.role ?? null;

      setField(newField);

      setCurrentPlayer(moveInfo.nextUserID);

      return;
    }
  };

  const makeMove = (column: number) => {
    wsClient.send({
      type: MessageTypes.MakeTurn,
      data: {
        move: {
          column,
        },
      } as CSMakeTurn,
    });
  };

  useEffect(() => {
    onMessage(wsClient.lastMessage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient.lastMessage]);

  useEffect(() => {
    props.setGameinfoEles(
      props.gameInfo.turnOrder.map((player) => {
        return {
          userID: player.userID,
          element: (
            <Gameinfo
              player={player}
              currentTurn={player.userID === currentPlayer}
            />
          ),
        } as TobbarGameinfo;
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlayer]);

  return (
    <div className="connectfourgame-wrapper">
      <div className="game-container">
        {[...Array(7 * 6).keys()].map((index) => (
          <button className="tile" onClick={() => makeMove(index % 7)}>
            {field[index % 7][
              Math.trunc(index / 7) * -1 + field[0].length - 1
            ] !== null && (
              <div
                className={`chip ${field[index % 7][Math.trunc(index / 7) * -1 + field[0].length - 1]}`}
              ></div>
            )}
          </button>
        ))}
      </div>
    </div>
  );
}

function Gameinfo(props: { player: C4Player; currentTurn: boolean }) {
  const { t } = useTranslation();

  return (
    <div className="connectfour-gameinfo">
      <p>
        {props.player.role === C4Role.Red
          ? t("gameC4.colorRed")
          : t("gameC4.colorRed")}
      </p>
      {props.currentTurn && <p>{t("c.theirTurn")}</p>}
    </div>
  );
}
