import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./Room.css";

import { AppContext } from "../../components/AppContextProvider/AppContextProvider";
import { Chat } from "../../components/Chat/Chat";
import ProfileImage from "../../components/ProfileImage/ProfileImage";
import SettingsButton from "../../components/SettingsButton/SettingsButton";
import { C4GameInfo, MMRYGameInfo, TTTGameInfo } from "../../utils/games.types";
import {
  CSChatMessage,
  ConnectedUser,
  DecisionMaker,
  Games,
  Message,
  MessageTypes,
  SSChatMessage,
  SSChooseGame,
  SSEndGame,
  SSVoteEndGame,
  StartGame,
  TobbarGameinfo,
} from "../../utils/types";
import GameConnectFour from "../GameConnectFour/GameConnectFour";
import GameMemory from "../GameMemory/GameMemory";
import GameTicTacToe from "../GameTicTacToe/GameTicTacToe";
import RoomSelectGame from "../RoomSelectGame/RoomSelectGame";

export default function Room() {
  const { settings, wsClient } = useContext(AppContext);
  const { t } = useTranslation();

  const [connectedUsers, setConnectedUsers] = useState<ConnectedUser[]>([]);
  const [roomState, setRoomState] = useState<Games | null>(null);
  const [gameInfo, setGameInfo] = useState<
    TTTGameInfo | C4GameInfo | MMRYGameInfo | null
  >(null);
  const [lastReaction, setLastReaction] = useState<SSChatMessage | null>(null);
  const [chat, setChat] = useState<SSChatMessage[]>([]);
  const [gameinfoEles, setGameinfoEles] = useState<TobbarGameinfo[]>([]);
  const [displayCopySuccess, setDisplayCopySuccess] = useState<boolean>(false);

  const onMessage = (message: Message | null) => {
    if (!message) return;

    switch (message.type) {
      case MessageTypes.Error: {
        console.log("Recieved Error:", message.data);
        break;
      }

      case MessageTypes.UsersInRoom: {
        console.log("Recieved UsersInRoom:", message.data);
        setConnectedUsers(message.data as ConnectedUser[]);
        break;
      }

      case MessageTypes.SendReaction: {
        console.log("Recieved SendReaction:", message.data);
        setLastReaction(message.data as SSChatMessage);
        break;
      }

      case MessageTypes.SendChat: {
        console.log("Recieved SendChat:", message.data);
        setChat([...chat, message.data as SSChatMessage]);
        break;
      }

      case MessageTypes.ChooseGame: {
        console.log("Recieved ChooseGame:", message.data);
        const chooseGame = message.data as SSChooseGame;
        setConnectedUsers(
          connectedUsers.map((usr) => {
            if (usr.id === chooseGame.userID) {
              return {
                ...usr,
                voteChooseGame: chooseGame.game,
              };
            } else {
              return usr;
            }
          }),
        );
        break;
      }

      case MessageTypes.StartGame: {
        console.log("Recieved StartGame:", message.data);
        const startGame = message.data as StartGame;
        setRoomState(startGame.game);
        setGameInfo(startGame.gameInfo);
        break;
      }

      case MessageTypes.VoteEndGame: {
        console.log("Recieved VoteEndGame:", message.data);
        const voteEndGame = message.data as SSVoteEndGame;
        setConnectedUsers(
          connectedUsers.map((usr) => {
            if (usr.id === voteEndGame.userID) {
              return {
                ...usr,
                voteEndGame: voteEndGame.vote,
              };
            } else {
              return usr;
            }
          }),
        );
        break;
      }

      case MessageTypes.EndGame: {
        console.log("Recieved EndGame:", message.data);

        setRoomState(null);
        setGameInfo(null);
        setGameinfoEles([]);

        const endGame = message.data as SSEndGame;

        setConnectedUsers(
          connectedUsers.map((user) => {
            if (user.id === endGame.winnerID) {
              return {
                ...user,
                score: user.score + 1,
              };
            } else {
              return user;
            }
          }),
        );

        break;
      }

      default: {
        console.error("Recieved unknown message type:", message);
        break;
      }
    }
  };

  const sendReaction = (reaction: CSChatMessage) => {
    wsClient.send({
      type: MessageTypes.SendReaction,
      data: reaction,
    });
  };

  const sendChat = (message: CSChatMessage) => {
    wsClient.send({
      type: MessageTypes.SendChat,
      data: message,
    });
  };

  // React to messages
  useEffect(() => {
    onMessage(wsClient.lastMessage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient.lastMessage]);

  // Tell the backend I joined the room
  useEffect(() => {
    wsClient.send({
      type: MessageTypes.SuccessfullyJoined,
      data: null,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="room-page-wrapper">
      <div className="room-page">
        <div className="topbar-wrapper">
          <div className="scrollpart-wrapper">
            {connectedUsers.map((user, index) => (
              <div className="scrollpart-item" key={index}>
                <div className="content user-container background-elevated">
                  <div className="icon">
                    <ProfileImage user={user} />
                  </div>
                  <div className="name">
                    <span>{user.data.name}</span>
                  </div>
                  <div className="score">
                    <span>
                      {user.score} {t("room.points")}
                    </span>
                  </div>
                </div>
                <div className="gameinfo">
                  {gameinfoEles.find((ele) => ele.userID === user.id)?.element}
                </div>
              </div>
            ))}
            {connectedUsers.length < (settings.room?.settings.size ?? 4) && (
              <div className="scrollpart-item">
                <div className="content code-container background-elevated">
                  <span className="code">
                    {settings.room?.code.toUpperCase() ?? ""}
                  </span>
                  <button
                    className="copy interactable"
                    onClick={() => {
                      if (settings.room) {
                        navigator.clipboard
                          .writeText(settings.room.code.toUpperCase())
                          .then(() => {
                            setDisplayCopySuccess(true);
                            setTimeout(() => {
                              setDisplayCopySuccess(false);
                            }, 1000);
                          });
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      icon={displayCopySuccess ? faCheck : faCopy}
                    />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <SettingsButton
          connectedUsers={connectedUsers}
          changeColorScheme
          changeLanguage
          endGame={
            roomState !== null &&
            (settings.user?.id === connectedUsers[0].id ||
              settings.room?.settings.decisionCancel === DecisionMaker.Everyone)
          }
          leaveRoom
        />
        <div className="room-page-content">
          {roomState === null && (
            <RoomSelectGame
              isHost={connectedUsers[0]?.id === settings.user?.id}
              connectedUsers={connectedUsers}
            />
          )}
          {roomState === Games.TicTacToe && (
            <GameTicTacToe
              gameInfo={gameInfo as TTTGameInfo}
              setGameinfoEles={setGameinfoEles}
            />
          )}
          {roomState === Games.ConnectFour && (
            <GameConnectFour
              gameInfo={gameInfo as C4GameInfo}
              setGameinfoEles={setGameinfoEles}
            />
          )}
          {roomState === Games.Memory && (
            <GameMemory
              gameInfo={gameInfo as MMRYGameInfo}
              setGameinfoEles={setGameinfoEles}
            />
          )}
        </div>
      </div>
      <Chat
        connectedUsers={connectedUsers}
        lastReaction={lastReaction}
        chat={chat}
        sendReaction={sendReaction}
        sendChat={sendChat}
      />
    </div>
  );
}
