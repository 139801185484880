import i18next from "i18next";
import { useState } from "react";

import {
  ColorSchemeValues,
  LanguageValues,
  SettingsInterface,
  StorageKeys,
} from "./types";

import { Room, User } from "../types";

export function Settings(): SettingsInterface {
  const [userToken, setUserToken] = useLocalStorage<string | null>(
    StorageKeys.UserToken,
    null,
  );

  const [user, setUser] = useLocalStorage<User | null>(StorageKeys.User, null);

  const [room, setRoom] = useLocalStorage<Room | null>(StorageKeys.Room, null);

  const [colorScheme, setLSColorScheme] = useLocalStorage<ColorSchemeValues>(
    StorageKeys.ColorScheme,
    ColorSchemeValues.System,
  );

  const setColorScheme = (newColorScheme: ColorSchemeValues) => {
    setLSColorScheme(newColorScheme);

    const htmlElement = document.querySelector("html")!;

    htmlElement.classList.remove(ColorSchemeValues.Light);
    htmlElement.classList.remove(ColorSchemeValues.Dark);

    if (newColorScheme !== ColorSchemeValues.System) {
      htmlElement.classList.add(newColorScheme);
    }
  };

  const [language, setLSLanguage] = useLocalStorage<LanguageValues>(
    StorageKeys.Language,
    LanguageValues.System,
  );

  const setLanguage = (newLanguage: LanguageValues) => {
    setLSLanguage(newLanguage);

    if (newLanguage === LanguageValues.System) {
      i18next.changeLanguage().catch((err) => console.log(err));
    } else {
      i18next.changeLanguage(newLanguage).catch((err) => console.log(err));
    }
  };

  return {
    userToken,
    setUserToken,
    user,
    setUser,
    room,
    setRoom,
    colorScheme,
    setColorScheme,
    language,
    setLanguage,
  };
}

function useLocalStorage<ValueType>(
  keyName: StorageKeys,
  defaultValue: ValueType,
): [ValueType, (newValue: ValueType) => void] {
  // Create the react state and initialize it
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value) {
        return JSON.parse(value) as ValueType;
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      console.error("Use local storage err:", err);
      return defaultValue;
    }
  });

  // Function to properly update it
  const setValue = (newValue: ValueType) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {
      console.error("Set local storage err:", err);
    }
    setStoredValue(newValue);
  };

  // Return the appropriate functions
  return [storedValue, setValue];
}
