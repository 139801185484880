import { Route, Routes } from "react-router-dom";

import "./App.css";
import AppContextProvider from "./components/AppContextProvider/AppContextProvider";
import Home from "./pages/Home/Home";
import NoMatch from "./pages/NoMatch/NoMatch";
import Room from "./pages/Room/Room";

function App() {
  return (
    <AppContextProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/room/:name" element={<Room />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </AppContextProvider>
  );
}

export default App;
