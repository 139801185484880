import { Room, User } from "../types";

export enum StorageKeys {
  UserToken = "userToken",
  User = "user",
  Room = "room",
  ColorScheme = "colorScheme",
  Language = "language",
}

export enum ColorSchemeValues {
  System = "system",
  Light = "lightmode",
  Dark = "darkmode",
}

export enum LanguageValues {
  System = "system",
  German = "de",
  English = "en",
}

export interface SettingsInterface {
  userToken: string | null;
  setUserToken: (newToken: string | null) => void;
  user: User | null;
  setUser: (newUser: User | null) => void;
  room: Room | null;
  setRoom: (newRoom: Room | null) => void;
  colorScheme: ColorSchemeValues;
  setColorScheme: (newColorScheme: ColorSchemeValues) => void;
  language: LanguageValues;
  setLanguage: (newLanguage: LanguageValues) => void;
}
