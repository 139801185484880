import { WebSocketHook } from "react-use-websocket/dist/lib/types";

import { WSClientInteface } from "./types";

import { Message } from "../types";

export function WSClient(webSocket: WebSocketHook): WSClientInteface {
  const send = (message: Message) => {
    webSocket.sendMessage(JSON.stringify(message));
  };

  const lastMessage =
    webSocket.lastMessage === null
      ? null
      : (JSON.parse(webSocket.lastMessage.data) as Message);

  return {
    send,
    lastMessage,
  };
}
