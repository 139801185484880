import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import "./ProfileEditor.css";

import { UserPictureType, UserProfile } from "../../utils/types";
import { AppContext } from "../AppContextProvider/AppContextProvider";
import { TabSelector } from "../TabSelector/TabSelector";

export function ProfileEditor(props: { close: VoidFunction }) {
  const { settings, apiClient } = useContext(AppContext);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [type, setType] = useState<UserPictureType>(
    settings.user?.data.picture.type ?? UserPictureType.Image,
  );
  const [selectedImage, setSelectedImage] = useState<number>(
    settings.user?.data.picture.image ?? 0,
  );
  const [selectedBackground, setSelectedBackground] = useState<string>(
    settings.user?.data.picture.background ?? "#ffffff",
  );
  const [selectedEmoji, setSelectedEmoji] = useState<string>(
    settings.user?.data.picture.emoji ?? "😀",
  );

  const availableEmojis = () => {
    const emojis = [...Array(0x45).keys()].map((ele) => {
      return String.fromCodePoint(ele + 0x1f600);
    });

    emojis.push("🏳️‍🌈");
    emojis.push("🏳️‍⚧️");

    return emojis;
  };

  const resetAndClose = () => {
    setType(settings.user?.data.picture.type ?? UserPictureType.Image);
    setSelectedImage(settings.user?.data.picture.image ?? 0);
    setSelectedBackground(settings.user?.data.picture.background ?? "#ffffff");
    setSelectedEmoji(settings.user?.data.picture.emoji ?? "😀");
    props.close();
  };

  const handleUpdateUser = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Indicate loading
    setLoading(true);

    // Check for user token
    if (settings.userToken === null) {
      console.error("Create Room Error: userToken === null");
      setLoading(false);
      return;
    }

    // Get data about the room
    const form = new FormData(event.currentTarget);
    const name = form.get("name") as string;
    const type = form.get("type") as UserPictureType;
    const image = parseInt(form.get("image") as string);
    const emoji = form.get("emoji") as string;
    const background = form.get("background") as string;
    const data: UserProfile = {
      name,
      picture: {
        type,
        image,
        emoji,
        background,
      },
    };

    // Update user
    apiClient
      .updateUser(settings.userToken, data)
      .then((user) => {
        settings.setUser(user);
        setLoading(false);
        props.close();
      })
      .catch((err) => {
        console.error("Error updating user:", err);
        setLoading(false);
      });
  };

  return (
    <div className="profileeditor-wrapper">
      <form onSubmit={handleUpdateUser}>
        <div className="header">
          <h2>{t("profileEditor.editProfile")}</h2>
          <button
            type="button"
            onClick={() => resetAndClose()}
            disabled={loading}
            className="interactable"
          >
            {loading && <FontAwesomeIcon icon={faSpinner} pulse />}
            <span>{t("c.cancel")}</span>
          </button>
          <button type="submit" disabled={loading} className="interactable">
            {loading && <FontAwesomeIcon icon={faSpinner} pulse />}
            <span>{t("c.save")}</span>
          </button>
        </div>
        <div className="input-name-container">
          <h3>{t("profileEditor.yourName")}</h3>
          <input
            type="text"
            name="name"
            placeholder={t("c.name")}
            defaultValue={settings.user?.data.name}
            className="interactable-container"
            required
          />
        </div>
        <div className="input-type-container">
          <h3>{t("profileEditor.picOrEmoji")}</h3>
          <TabSelector
            name="type"
            initialValue={type}
            options={[
              {
                value: UserPictureType.Image,
                label: t("c.picture"),
              },
              {
                value: UserPictureType.Emoji,
                label: t("c.emoji"),
              },
            ]}
            onChange={(event) => {
              setType(event.currentTarget.value as UserPictureType);
            }}
          />
        </div>
        <div
          className={`input-image-container ${type !== UserPictureType.Image && "hidden"}`}
        >
          <h3>{t("profileEditor.yourPicture")}</h3>
          <div className="inputs">
            {[...Array(9).keys()].map((index) => (
              <label
                key={index}
                className={selectedImage === index ? "selected" : ""}
              >
                <img
                  src={`/img/profile/${index}.png`}
                  alt={`${t("c.picture")} ${index}`}
                />
                <input
                  type="radio"
                  name="image"
                  value={index}
                  defaultChecked={selectedImage === index}
                  onChange={(event) => {
                    setSelectedImage(parseInt(event.currentTarget.value));
                  }}
                  required
                />
              </label>
            ))}
          </div>
        </div>
        <div
          className={`input-background-container ${type !== UserPictureType.Emoji ? "hidden" : ""}`}
        >
          <h3>{t("profileEditor.yourBackground")}</h3>
          <input
            type="color"
            name="background"
            defaultValue={selectedBackground}
            onChange={(event) => {
              setSelectedBackground(event.currentTarget.value);
            }}
            required
          />
        </div>
        <div
          className={`input-emoji-container ${type !== UserPictureType.Emoji ? "hidden" : ""}`}
        >
          <h3>{t("profileEditor.yourEmoji")}</h3>
          <div className="inputs">
            {availableEmojis().map((emoji, index) => (
              <label
                key={index}
                className={selectedEmoji === emoji ? "selected" : ""}
              >
                <span style={{ backgroundColor: selectedBackground }}>
                  {emoji}
                </span>
                <input
                  type="radio"
                  name="emoji"
                  value={emoji}
                  defaultChecked={selectedEmoji === emoji}
                  onChange={(event) => {
                    setSelectedEmoji(event.currentTarget.value);
                  }}
                  required
                />
              </label>
            ))}
          </div>
        </div>
      </form>
    </div>
  );
}
