import { APIClientInterface, CreatedUser } from "./types";

import {
  BackendError,
  JoinRoom,
  Room,
  RoomData,
  User,
  UserProfile,
} from "../types";

export const APIClient: APIClientInterface = {
  getUser: async (token: string) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_BASE}/node-user`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await res.json();

      if (res.status !== 200) {
        const recievedError = data as BackendError;
        throw recievedError;
      }

      return data as User;
    } catch (err) {
      console.log("APIClient Error in getUser:", err);
      const error: BackendError = {
        code: "E03-01",
        message: `Fetch failed: ${err}`,
      };
      throw error;
    }
  },

  createUser: async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_BASE}/node-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await res.json();

      if (res.status !== 201) {
        const recievedError = data as BackendError;
        throw recievedError;
      }

      return data as CreatedUser;
    } catch (err) {
      console.log("APIClient Error in createUser:", err);
      const error: BackendError = {
        code: "E03-01",
        message: `Fetch failed: ${err}`,
      };
      throw error;
    }
  },

  updateUser: async (token: string, userProfile: UserProfile) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_BASE}/node-user`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userProfile),
      });

      const data = await res.json();

      if (res.status !== 200) {
        const recievedError = data as BackendError;
        throw recievedError;
      }

      return data as User;
    } catch (err) {
      console.log("APIClient Error in updateUser:", err);
      const error: BackendError = {
        code: "E03-01",
        message: `Fetch failed: ${err}`,
      };
      throw error;
    }
  },

  createRoom: async (token: string, roomData: RoomData) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_BASE}/node-room`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(roomData),
      });

      const resData = await res.json();

      if (res.status !== 201) {
        const recievedError = resData as BackendError;
        throw recievedError;
      }

      return resData as Room;
    } catch (err) {
      console.log("APIClient Error in createRoom:", err);
      const error: BackendError = {
        code: "E03-01",
        message: `Fetch failed: ${err}`,
      };
      throw error;
    }
  },

  joinRoom: async (token: string, joinRoom: JoinRoom) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_BASE}/node-room`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(joinRoom),
      });

      const resData = await res.json();

      if (res.status !== 200) {
        const recievedError = resData as BackendError;
        throw recievedError;
      }

      return resData as Room;
    } catch (err) {
      console.log("APIClient Error in joinRoom:", err);
      const error: BackendError = {
        code: "E03-01",
        message: `Fetch failed: ${err}`,
      };
      throw error;
    }
  },
};
