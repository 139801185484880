import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import "./TabSelector.css";

export function TabSelector(props: {
  name: string;
  initialValue: string | number;
  options: { label: string | IconProp; value: string | number }[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const [selected, setSelected] = useState<string | number>(props.initialValue);

  return (
    <div className="tabselector-wrapper interactable-container">
      {props.options.map((option, index) => (
        <label
          key={index}
          className={
            option.value.toString() === selected.toString()
              ? "interactable"
              : ""
          }
        >
          <span>
            {typeof option.label === "string" ? (
              option.label
            ) : (
              <FontAwesomeIcon icon={option.label} />
            )}
          </span>
          <input
            type="radio"
            name={props.name}
            value={option.value}
            defaultChecked={option.value === selected}
            onChange={(event) => {
              setSelected(event.target.value);

              if (props.onChange) {
                props.onChange(event);
              }
            }}
            required
          />
        </label>
      ))}
    </div>
  );
}
