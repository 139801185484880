export enum TTTRole {
  X = "x",
  O = "o",
}

export interface TTTPlayer {
  userID: string;
  role: TTTRole;
}

export interface TTTGameInfo {
  startUserID: string;
  turnOrder: TTTPlayer[];
}

export interface TTTMove {
  xCoord: number;
  yCoord: number;
}

export interface TTTMoveInfo {
  byUserID: string;
  nextUserID: string;
}

export enum C4Role {
  Yellow = "yellow",
  Red = "red",
}

export interface C4Player {
  userID: string;
  role: C4Role;
}

export interface C4GameInfo {
  startUserID: string;
  turnOrder: C4Player[];
}

export interface C4Move {
  column: number;
}

export interface C4MoveInfo {
  byUserID: string;
  nextUserID: string;
}

export interface MMRYPlayer {
  userID: string;
  score: number;
}

export interface MMRYGameInfo {
  startUserID: string;
  turnOrder: MMRYPlayer[];
}

export interface MMRYMove {
  index: number;
}

export interface MMRYMoveInfo {
  byUserID: string;
  nextUserID: string;
  increaseScoreOf: string | null;
  revealedByMove: string;
}

export interface MMRYTile {
  symbol: string;
  revealedBy: string | null;
  midTurnUncovered: boolean;
}
