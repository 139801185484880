import { useContext } from "react";
import { useTranslation } from "react-i18next";

import "./RoomSelectGame.css";

import { AppContext } from "../../components/AppContextProvider/AppContextProvider";
import {
  CSChooseGame,
  ConnectedUser,
  DecisionMaker,
  Games,
  MessageTypes,
} from "../../utils/types";

export default function RoomSelectGame(props: {
  isHost: boolean;
  connectedUsers: ConnectedUser[];
}) {
  const { wsClient, settings } = useContext(AppContext);
  const { t } = useTranslation();

  const chooseGame = (game: Games | null) => {
    wsClient.send({
      type: MessageTypes.ChooseGame,
      data: {
        game: game,
      } as CSChooseGame,
    });
  };

  const thisPlayer = () => {
    return props.connectedUsers.find((usr) => usr.id === settings.user?.id);
  };

  return (
    <div className="selectgame-wrapper">
      <h1>{t("roomSelectGame.whatYouWannaPlay")}</h1>
      <div className="games-wrapper">
        <GameContainer
          title={t("c.ticTacToe")}
          description={t("roomSelectGame.twoPlayers")}
          imgPath="/img/game/tictactoe.png"
          game={Games.TicTacToe}
          decisionMaker={settings.room?.settings.decisionNext!}
          isHost={props.isHost}
          currentVote={thisPlayer()?.voteChooseGame ?? null}
          amountVotes={
            props.connectedUsers.filter(
              (usr) => usr.voteChooseGame === Games.TicTacToe,
            ).length
          }
          enoughPlayers={props.connectedUsers.length === 2}
          chooseGame={chooseGame}
        />
        <GameContainer
          title={t("c.connectFour")}
          description={t("roomSelectGame.twoPlayers")}
          imgPath="/img/game/connectfour.png"
          game={Games.ConnectFour}
          decisionMaker={settings.room?.settings.decisionNext!}
          isHost={props.isHost}
          currentVote={thisPlayer()?.voteChooseGame ?? null}
          amountVotes={
            props.connectedUsers.filter(
              (usr) => usr.voteChooseGame === Games.ConnectFour,
            ).length
          }
          enoughPlayers={props.connectedUsers.length === 2}
          chooseGame={chooseGame}
        />
        <GameContainer
          title={t("c.memory")}
          description={t("roomSelectGame.twoToFourPlayers")}
          imgPath="/img/game/memory.png"
          game={Games.Memory}
          decisionMaker={settings.room?.settings.decisionNext!}
          isHost={props.isHost}
          currentVote={thisPlayer()?.voteChooseGame ?? null}
          amountVotes={
            props.connectedUsers.filter(
              (usr) => usr.voteChooseGame === Games.Memory,
            ).length
          }
          enoughPlayers={props.connectedUsers.length >= 2}
          chooseGame={chooseGame}
        />
      </div>
    </div>
  );
}

function GameContainer(props: {
  title: string;
  description: string;
  imgPath: string;
  game: Games;
  decisionMaker: DecisionMaker;
  isHost: boolean;
  currentVote: Games | null;
  amountVotes: number;
  enoughPlayers: boolean;
  chooseGame: (game: Games | null) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="game-container background-elevated">
      <div className="header-wrapper">
        <img src={props.imgPath} alt="" />
        <h3 className="background-elevated">{props.title}</h3>
      </div>
      <div className="body-wrapper">
        <p>{props.description}</p>
      </div>
      <div className="button-wrapper">
        {props.decisionMaker === DecisionMaker.Host && props.isHost && (
          <button
            className="playgame-button accent"
            onClick={() => props.chooseGame(props.game)}
            disabled={!props.enoughPlayers}
          >
            {t("c.start")}
          </button>
        )}
        {props.decisionMaker === DecisionMaker.Everyone && (
          <span>
            {t("c.votes")}: {props.amountVotes}
          </span>
        )}
        {props.decisionMaker === DecisionMaker.Everyone &&
          (props.currentVote === props.game ? (
            <button
              className="playgame-button accent"
              onClick={() => props.chooseGame(null)}
            >
              {t("c.cancel")}
            </button>
          ) : (
            <button
              className="playgame-button accent"
              onClick={() => props.chooseGame(props.game)}
              disabled={!props.enoughPlayers}
            >
              {t("c.vote")}
            </button>
          ))}
      </div>
    </div>
  );
}
