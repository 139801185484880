import { useTranslation } from "react-i18next";

import "./ProfileImage.css";

import { User, UserPictureType } from "../../utils/types";

export default function ProfileImage(props: {
  user: User | null;
  size?: number;
}) {
  return (
    <div
      className="profileimage-wrapper"
      style={props.size ? { width: props.size, height: props.size } : {}}
    >
      <div className="profileimage-container background">
        {props.user &&
          (props.user.data.picture.type === UserPictureType.Image ? (
            <ProfileIsImage user={props.user} />
          ) : (
            <ProfileIsEmoji user={props.user} />
          ))}
      </div>
    </div>
  );
}

function ProfileIsImage(props: { user: User }) {
  const { t } = useTranslation();

  return (
    <img
      className="profile-image"
      src={`/img/profile/${props.user.data.picture.image}.png`}
      alt={`${t("profileImage.pictureOf")} ${props.user.data.name}`}
    />
  );
}

function ProfileIsEmoji(props: { user: User }) {
  return (
    <div
      className="profile-emoji"
      style={{
        backgroundColor: props.user.data.picture.background,
      }}
    >
      {props.user.data.picture.emoji}
    </div>
  );
}
