import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./NoMatch.css";

export default function NoMatch() {
  const { t } = useTranslation();

  return (
    <div className="nomatch-wrapper">
      <h2>{t("404.notFound")}</h2>
      <p>
        <Link to="/">{t("404.linkHome")}</Link>.
      </p>
    </div>
  );
}
