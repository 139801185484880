import {
  faCog,
  faDesktop,
  faMoon,
  faRightFromBracket,
  faSun,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./SettingsButton.css";

import { ColorSchemeValues, LanguageValues } from "../../utils/settings/types";
import {
  CSVoteEndGame,
  ConnectedUser,
  DecisionMaker,
  MessageTypes,
} from "../../utils/types";
import { AppContext } from "../AppContextProvider/AppContextProvider";
import { TabSelector } from "../TabSelector/TabSelector";

export default function SettingsButton(props: {
  connectedUsers: ConnectedUser[];
  changeColorScheme?: boolean;
  changeLanguage?: boolean;
  endGame?: boolean;
  leaveRoom?: boolean;
}) {
  const { settings, wsClient } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dialogRef = useRef<HTMLDialogElement>(null);

  const endGame = (vote: boolean) => {
    // Tell the backend I want to end the game early
    wsClient.send({
      type: MessageTypes.VoteEndGame,
      data: {
        vote,
      } as CSVoteEndGame,
    });

    dialogRef.current?.close();
  };

  const leaveRoom = () => {
    // Tell the backend I leave the room
    wsClient.send({
      type: MessageTypes.LeaveRoom,
      data: null,
    });

    // Delete room from memory
    settings.setRoom(null);

    dialogRef.current?.close();

    // Go to the home page
    navigate("/");
  };

  const thisUser = () => {
    return props.connectedUsers.find((user) => user.id === settings.user?.id)!;
  };

  return (
    <div className="settingsbutton-wrapper">
      <button
        onClick={() => dialogRef.current?.showModal()}
        className="interactable"
      >
        <FontAwesomeIcon icon={faCog} />
      </button>
      <dialog
        ref={dialogRef}
        className="dialog-settings"
        onClick={(e) => {
          if (e.target === dialogRef.current) dialogRef.current.close();
        }}
      >
        <div className="settings-popup background-elevated">
          {props.changeColorScheme && (
            <div className="colorscheme-container">
              <p>{t("settingsButton.colorScheme")}</p>
              <TabSelector
                name="colorScheme"
                initialValue={settings.colorScheme}
                options={[
                  { label: faSun, value: ColorSchemeValues.Light },
                  { label: faDesktop, value: ColorSchemeValues.System },
                  { label: faMoon, value: ColorSchemeValues.Dark },
                ]}
                onChange={(event) => {
                  settings.setColorScheme(
                    event.target.value as ColorSchemeValues,
                  );
                }}
              />
            </div>
          )}
          {props.changeLanguage && (
            <div className="language-container">
              <p>{t("settingsButton.language")}</p>
              <TabSelector
                name="language"
                initialValue={settings.language}
                options={[
                  { label: faDesktop, value: LanguageValues.System },
                  { label: "🇩🇪", value: LanguageValues.German },
                  { label: "🇬🇧", value: LanguageValues.English },
                ]}
                onChange={(event) => {
                  settings.setLanguage(event.target.value as LanguageValues);
                }}
              />
            </div>
          )}
          {props.endGame && (
            <button
              className="settingsitem-button interactable"
              onClick={() => {
                endGame(!thisUser().voteEndGame);
              }}
            >
              <FontAwesomeIcon icon={faRightFromBracket} />
              <span>
                {thisUser().voteEndGame
                  ? t("settingsButton.continueGame")
                  : t("settingsButton.cancelGame")}
              </span>
              {settings.room?.settings.decisionCancel ===
                DecisionMaker.Everyone && (
                <span>
                  {props.connectedUsers.filter((usr) => usr.voteEndGame).length}
                  /{props.connectedUsers.length}
                </span>
              )}
            </button>
          )}
          {props.leaveRoom && (
            <button
              className="settingsitem-button interactable"
              onClick={leaveRoom}
            >
              <FontAwesomeIcon icon={faRightFromBracket} />
              <span>{t("settingsButton.leaveRoom")}</span>
            </button>
          )}
        </div>
      </dialog>
    </div>
  );
}
