import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./Home.css";

import { AppContext } from "../../components/AppContextProvider/AppContextProvider";
import { ProfileEditor } from "../../components/ProfileEditor/ProfileEditor";
import ProfileImage from "../../components/ProfileImage/ProfileImage";
import SettingsButton from "../../components/SettingsButton/SettingsButton";
import { TabSelector } from "../../components/TabSelector/TabSelector";
import { DecisionMaker, JoinRoom, RoomData } from "../../utils/types";

export default function Home() {
  const { settings, apiClient } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const dialogRef = useRef<HTMLDialogElement>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCreateRoom = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Indicate loading
    setLoading(true);

    // Check for user token
    if (settings.userToken === null) {
      console.error("Create Room Error: userToken === null");
      setLoading(false);
      return;
    }

    // Get data about the room
    const form = new FormData(event.currentTarget);
    const size = parseInt(form.get("size") as string);
    const decisionNext = form.get("decisionNext") as DecisionMaker;
    const decisionCancel = form.get("decisionCancel") as DecisionMaker;
    const data: RoomData = {
      size,
      decisionNext,
      decisionCancel,
    };

    // Create room and load into it
    apiClient
      .createRoom(settings.userToken, data)
      .then((room) => {
        settings.setRoom(room);
        setLoading(false);
        navigate(`/room/${room.name}`);
      })
      .catch((err) => {
        console.error("Error creating room:", err);
        setLoading(false);
      });
  };

  const handleJoinRoom = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Indicate loading
    setLoading(true);

    // Check for user token
    if (settings.userToken === null) {
      console.error("Create Room Error: userToken === null");
      setLoading(false);
      return;
    }

    // Get data about the room
    const form = new FormData(event.currentTarget);
    const code = form.get("code") as string;
    const data: JoinRoom = { code };

    // Join room and load into it
    apiClient
      .joinRoom(settings.userToken, data)
      .then((room) => {
        settings.setRoom(room);
        setLoading(false);
        navigate(`/room/${room.name}`);
      })
      .catch((err) => {
        console.error("Error joining room:", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (dialogOpen) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [dialogOpen]);

  return (
    <div className="home-page">
      <dialog
        ref={dialogRef}
        className="dialog-home background"
        onCancel={() => setDialogOpen(false)}
      >
        <ProfileEditor close={() => setDialogOpen(false)} />
      </dialog>
      <SettingsButton connectedUsers={[]} changeColorScheme changeLanguage />
      <div className="profile-container background-elevated">
        <div className="image">
          <ProfileImage user={settings.user} />
        </div>
        <div className="name">
          <h2>
            {settings.user !== null ? settings.user.data.name : t("c.loading")}
          </h2>
        </div>
        <div className="edit">
          <button
            onClick={() => setDialogOpen(true)}
            className="loading-button interactable"
            disabled={!settings.user || loading}
          >
            {loading && <FontAwesomeIcon icon={faSpinner} pulse />}
            <span>{t("c.edit")}</span>
          </button>
        </div>
      </div>
      <div className="room-wrapper">
        <form
          className="create-container background-elevated"
          onSubmit={handleCreateRoom}
        >
          <h2>{t("home.createRoom")}</h2>
          <h3>{t("home.size")}</h3>
          <TabSelector
            name="size"
            initialValue={4}
            options={[
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
            ]}
          />
          <h3>{t("home.decisionNext")}</h3>
          <TabSelector
            name="decisionNext"
            initialValue={DecisionMaker.Host}
            options={[
              { value: DecisionMaker.Host, label: t("c.host") },
              { value: DecisionMaker.Everyone, label: t("c.everyone") },
            ]}
          />
          <h3>{t("home.decisionCancel")}</h3>
          <TabSelector
            name="decisionCancel"
            initialValue={DecisionMaker.Host}
            options={[
              { value: DecisionMaker.Host, label: t("c.host") },
              { value: DecisionMaker.Everyone, label: t("c.everyone") },
            ]}
          />
          <button
            type="submit"
            className="loading-button accent"
            disabled={!settings.userToken || loading}
          >
            {loading && <FontAwesomeIcon icon={faSpinner} pulse />}
            <span>{t("c.create")}</span>
          </button>
        </form>
        <form
          className="join-container background-elevated"
          onSubmit={handleJoinRoom}
        >
          <h2>{t("home.joinRoom")}</h2>
          <input
            type="text"
            name="code"
            placeholder={t("c.code")}
            autoCapitalize="characters"
            maxLength={8}
            onChange={(e) => {
              e.currentTarget.value = e.currentTarget.value.toUpperCase();
            }}
            autoComplete="code"
            className="interactable-container"
          />
          <button
            type="submit"
            className="loading-button accent"
            disabled={!settings.userToken || loading}
          >
            {loading && <FontAwesomeIcon icon={faSpinner} pulse />}
            <span>{t("c.join")}</span>
          </button>
        </form>
      </div>
    </div>
  );
}
